<template>
	<div
		id="mainHeader"
		class="ng-header ng-header-fixed ng-header-fixed"
		:class="{ 'is-light': !dark, 'is-dark': dark }"
	>
		<div class="container-lg wide-xl">
			<div class="ng-header-wrap">
				<div class="ng-header-brand d-print-none">
					<router-link to="/" class="logo-link">
						<img
							class="logo-light logo-img"
							src="/img/favicon.png"
							:alt="company"
						/>
						<img
							class="logo-dark logo-img"
							src="/img/favicon.png"
							:alt="company"
						/>
						<span class="nio-version d-none">v0.1</span>
					</router-link>
				</div>

				<div class="d-none d-print-block w-100 p-1">
					<div class="d-flex justify-content-between">
						<div class="d-inline-block">
							<div class="user-card">
								<div class="user-avatar user-avatar-lg">
									<img
										src="/img/favicon.png"
										:alt="company"
									/>
								</div>
								<div class="user-info">
									<h1 class="lead-text text-uppercase text-primary">
										{{ company }}
									</h1>
									<p class="sub-text">
										Growers & Exporters of <br />
										Premium Kenyan Produce <br />
									</p>
								</div>
							</div>
						</div>
						<div class="justify-content-end">
							<ul class="list-plain float-right">
								<li>
									<em class="icon ni ni-call mr-1"></em>
									<span>+447971787620</span>
								</li>
								<li>
									<em class="icon ni ni-call-alt mr-1"></em>
									<span>+254796388306</span>
								</li>
								<li>
									<em class="icon ni ni-map-pin mr-1"></em>
									<span> Pipeline Road, Kiserian</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<a
					href="#"
					class="ng-quick-nav-icon d-sm-inline-block d-none d-print-none ml-1 align-middle"
					@click.prevent="toggleNav"
				>
					<em class="icon ni ni-shrink" v-if="fullscreen"></em>
					<em class="icon ni ni-expand" v-else></em>
				</a>

				<h3 class="align-middle d-print-none">{{ department }}</h3>

				<div class="ng-header-tools d-print-none">
					<ul class="ng-quick-nav">
						<li class="dropdown" v-if="user.role !== 'customer'">
							<a
								href="#"
								class="dropdown-toggle ng-quick-nav-icon mr-lg-n1"
								data-toggle="dropdown"
							>
								<em class="icon ni ni-grid-alt"></em>
							</a>
							<div
								class="dropdown-menu dropdown-menu-xl dropdown-menu-right dropdown-menu-s1"
							>
								<div class="dropdown-body">
									<div
										class="ng-notification"
										v-if="department !== 'Planning'"
									>
										<div
											class="ng-notification-item dropdown-inner"
										>
											<a
												:href="
													departmentUrl(
														'VUE_APP_PLAN_URL'
													)
												"
												target="_blank"
												class="ng-notification-icon"
											>
												<em
													class="icon icon-circle bg-primary ni ni-swap"
												></em>
											</a>
											<div
												class="ng-notification-content"
											>
												<div
													class="ng-notification-text"
												>
													Planning
													<span>Dashboard</span>
												</div>
												<div
													class="ng-notification-time"
												>
													<a
														:href="
															departmentUrl(
																'VUE_APP_PLAN_URL'
															)
														"
														target="_blank"
													>
														{{
															departmentUrl(
																"VUE_APP_PLAN_URL"
															)
														}}
													</a>
												</div>
											</div>
										</div>
									</div>

									<div
										class="ng-notification"
										v-if="department !== 'Pricing'"
									>
										<div
											class="ng-notification-item dropdown-inner"
										>
											<a
												:href="
													departmentUrl(
														'VUE_APP_PRICE_URL'
													)
												"
												target="_blank"
												class="ng-notification-icon"
											>
												<em
													class="icon icon-circle bg-primary ni ni-swap"
												></em>
											</a>
											<div
												class="ng-notification-content"
											>
												<div
													class="ng-notification-text"
												>
													Pricing
													<span>Dashboard</span>
												</div>
												<div
													class="ng-notification-time"
												>
													<a
														:href="
															departmentUrl(
																'VUE_APP_PRICE_URL'
															)
														"
														target="_blank"
													>
														{{
															departmentUrl(
																"VUE_APP_PRICE_URL"
															)
														}}
													</a>
												</div>
											</div>
										</div>
									</div>

									<div
										class="ng-notification"
										v-if="department !== 'Sales'"
									>
										<div
											class="ng-notification-item dropdown-inner"
										>
											<a
												:href="
													departmentUrl(
														'VUE_APP_SALE_URL'
													)
												"
												target="_blank"
												class="ng-notification-icon"
											>
												<em
													class="icon icon-circle bg-primary ni ni-swap"
												></em>
											</a>
											<div
												class="ng-notification-content"
											>
												<div
													class="ng-notification-text"
												>
													Sales
													<span>Dashboard</span>
												</div>
												<div
													class="ng-notification-time"
												>
													<a
														:href="
															departmentUrl(
																'VUE_APP_SALE_URL'
															)
														"
														target="_blank"
													>
														{{
															departmentUrl(
																"VUE_APP_SALE_URL"
															)
														}}
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</li>
						<li class="dropdown user-dropdown">
							<a
								href="#"
								class="dropdown-toggle"
								data-toggle="dropdown"
							>
								<div class="user-toggle">
									<div
										class="user-avatar sm bg-primary shadow"
									>
										<img
											:src="user.avatar"
											:alt="user.name"
										/>
									</div>
									<div
										class="user-name dropdown-indicator d-none d-sm-block"
									>
										{{ user.first_name }}
									</div>
								</div>
							</a>
							<div
								class="dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1"
							>
								<div
									class="dropdown-inner user-card-wrap bg-lighter"
								>
									<div class="user-card">
										<div
											class="user-avatar bg-transparent shadow"
										>
											<img
												:src="user.avatar"
												:alt="user.name"
											/>
										</div>
										<div class="user-info">
											<span class="lead-text">
												{{ user.name }}
											</span>
											<span class="sub-text">
												{{ user.email }}
											</span>
										</div>
										<div class="user-action">
											<router-link
												class="btn btn-icon mr-n2"
												to="/profile"
											>
												<em
													class="icon ni ni-account-setting"
												></em
											></router-link>
										</div>
									</div>
								</div>
								<div
									class="dropdown-inner"
									v-if="user.role == 'admin'"
								>
									<ul class="link-list">
										<li>
											<router-link to="/profile">
												<em
													class="icon ni ni-user-alt"
												></em>
												<span>
													View Profile
												</span>
											</router-link>
										</li>
										<li>
											<router-link to="/settings"
												><em
													class="icon ni ni-setting-alt"
												></em>
												<span>
													App Settings
												</span>
											</router-link>
										</li>
									</ul>
								</div>
								<div class="dropdown-inner">
									<ul class="link-list">
										<li>
											<a
												href="#"
												@click.prevent="logoutUser"
											>
												<em
													class="icon ni ni-signout"
												></em>
												<span>Sign out</span>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</li>
						<li
							class="dropdown notification-dropdown"
							:class="{ 'd-none': !notify }"
						>
							<a
								href="#"
								class="dropdown-toggle ng-quick-nav-icon mr-lg-n1"
								data-toggle="dropdown"
							>
								<div class="icon-status icon-status-info">
									<em class="icon ni ni-bell"></em>
								</div>
							</a>
							<div
								class="dropdown-menu dropdown-menu-xl dropdown-menu-right dropdown-menu-s1"
							>
								<div class="dropdown-head">
									<span class="sub-title ng-dropdown-title"
										>Notifications</span
									><a href="#">Mark All as Read</a>
								</div>
								<div class="dropdown-body">
									<div class="ng-notification">
										<div
											class="ng-notification-item dropdown-inner"
										>
											<div class="ng-notification-icon">
												<em
													class="icon icon-circle bg-warning-dim ni ni-curve-down-right"
												></em>
											</div>
											<div
												class="ng-notification-content"
											>
												<div
													class="ng-notification-text"
												>
													You have requested to
													<span>Widthdrawl</span>
												</div>
												<div
													class="ng-notification-time"
												>
													2 hrs ago
												</div>
											</div>
										</div>
										<div
											class="ng-notification-item dropdown-inner"
										>
											<div class="ng-notification-icon">
												<em
													class="icon icon-circle bg-success-dim ni ni-curve-down-left"
												></em>
											</div>
											<div
												class="ng-notification-content"
											>
												<div
													class="ng-notification-text"
												>
													Your
													<span>Deposit Order</span>
													is placed
												</div>
												<div
													class="ng-notification-time"
												>
													2 hrs ago
												</div>
											</div>
										</div>
										<div
											class="ng-notification-item dropdown-inner"
										>
											<div class="ng-notification-icon">
												<em
													class="icon icon-circle bg-warning-dim ni ni-curve-down-right"
												></em>
											</div>
											<div
												class="ng-notification-content"
											>
												<div
													class="ng-notification-text"
												>
													You have requested to
													<span>Widthdrawl</span>
												</div>
												<div
													class="ng-notification-time"
												>
													2 hrs ago
												</div>
											</div>
										</div>
										<div
											class="ng-notification-item dropdown-inner"
										>
											<div class="ng-notification-icon">
												<em
													class="icon icon-circle bg-success-dim ni ni-curve-down-left"
												></em>
											</div>
											<div
												class="ng-notification-content"
											>
												<div
													class="ng-notification-text"
												>
													Your
													<span>Deposit Order</span>
													is placed
												</div>
												<div
													class="ng-notification-time"
												>
													2 hrs ago
												</div>
											</div>
										</div>
										<div
											class="ng-notification-item dropdown-inner"
										>
											<div class="ng-notification-icon">
												<em
													class="icon icon-circle bg-warning-dim ni ni-curve-down-right"
												></em>
											</div>
											<div
												class="ng-notification-content"
											>
												<div
													class="ng-notification-text"
												>
													You have requested to
													<span>Widthdrawl</span>
												</div>
												<div
													class="ng-notification-time"
												>
													2 hrs ago
												</div>
											</div>
										</div>
										<div
											class="ng-notification-item dropdown-inner"
										>
											<div class="ng-notification-icon">
												<em
													class="icon icon-circle bg-success-dim ni ni-curve-down-left"
												></em>
											</div>
											<div
												class="ng-notification-content"
											>
												<div
													class="ng-notification-text"
												>
													Your
													<span>Deposit Order</span>
													is placed
												</div>
												<div
													class="ng-notification-time"
												>
													2 hrs ago
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="dropdown-foot center">
									<a href="#">View All</a>
								</div>
							</div>
						</li>
						<li class="d-lg-none d-print-none">
							<a
								href="#"
								class="toggle ng-quick-nav-icon mr-n1"
								data-target="sideNav"
							>
								<em class="icon ni ni-more-v-alt"></em>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>

	<div class="mb-4 d-none d-print-block"></div>
	<a
		class="btn btn-primary btn-lg btn-icon btn-round text-white d-none d-sm-flex d-print-none"
		style="position: fixed; bottom: 1%; right: 1%; z-index: 8000;"
		@click.prevent="printPage"
	>
		<em class="icon ni ni-printer"></em>
	</a>
</template>

<script lang="ts">
	import store from "../services/store";
	import { defineComponent, computed, onBeforeMount, ref } from "vue";
	import { format } from "date-fns";

	export default defineComponent({
		props: {
			routes: Array,
			user: Object,
			notify: Boolean,
		},

		emits: ["loggedout"],

		setup(props, { emit }) {
			function logoutUser() {
				store.dispatch("logout").then(() => {
					emit("loggedout");
				});
			}
			const department = process.env.VUE_APP_NAME;
			const company = process.env.VUE_APP_COMPANY;

			const fullscreen = ref(false);

			const dark = computed(() => false);
			const d = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches

			function departmentUrl(name: "VUE_APP_PRICE_URL") {
				return process.env[name];
			}

			function toggleNav() {
				document.getElementById("sideNav")?.classList.toggle("d-none");
				fullscreen.value = !fullscreen.value;
			}

			function formatDate(d: any, f = "PPPP") {
				return format(d, f);
			}

			function printPage() {
				document.title = `${document.title} - ${formatDate(
					new Date(),
					"PPpp"
				)}`;
				window.print();
			}

			onBeforeMount(() => {
				if(d) {
					// document.body.classList.add('dark-mode')
					// document.body.setAttribute('theme', 'dark')
				}
			})

			return {
				logoutUser,
				department,
				company,
				toggleNav,
				printPage,
				departmentUrl,
				dark,
				fullscreen,
			};
		},
	});
</script>
