import { createApp } from "vue";
import App from "./App.vue";

import router from "./router";

import "./registerServiceWorker";

import "@vueform/multiselect/themes/default.css";
import "../../../common/assets/css/dashlite.css";

import DataTable from "../../../common/components/DataTable.vue";
import Pagination from "../../../common/components/Pagination.vue";
import Topbar from "../../../common/components/Topbar.vue";
import Sidebar from "../../../common/components/Sidebar.vue";
import Lowbar from "../../../common/components/Lowbar.vue";
import DTable from "../../../common/components/DTable.vue";
import Tools from "../../../common/components/Tools.vue";

import Multiselect from "@vueform/multiselect";
import VCalendar from "v-calendar";

const app = createApp(App);

app.component("topbar", Topbar)
app.component("sidebar", Sidebar)
app.component("lowbar", Lowbar)
app.component("Multiselect", Multiselect);
app.component("tools",Tools)
app.component("d-table", DTable)
app.component("data-table", DataTable);
app.component("paginate", Pagination);

app.use(VCalendar, {});

app.use(router);

app.mount("#app");
