<template>
	<div class="table-responsive pmd-table-card">
		<table
			class="table pmd-table table-orders"
			:class="{ 'table-light': !dark, 'table-dark': dark }"
		>
			<thead
				class="tb-odr-head"
				:class="{ 'thead-dark': dark }"
				v-if="records.length"
			>
				<tr class="tb-odr-item">
					<th
						class="ng-tb-col ng-tb-col-check d-print-none"
						tabindex="0"
						aria-controls="DataTables_Table_1"
						rowspan="1"
						colspan="1"
						aria-sort="ascending"
						aria-label=": activate to sort column descending"
					>
						<div
							class="custom-control custom-control-sm custom-checkbox notext"
						>
							<input
								type="checkbox"
								class="custom-control-input"
								id="uid"
								@click="selectAll"
							/>
							<label
								class="custom-control-label"
								for="uid"
							></label>
						</div>
					</th>
					<th
						v-for="(h, c) in headers"
						:key="c"
						class="text-truncate"
					>
						<strong>{{ h }}</strong>
					</th>
					<th class="tb-odr-action d-print-none">&nbsp;</th>
				</tr>
			</thead>

			<tbody class="tb-odr-body">
				<template v-if="records.length > 0">
					<tr
						class="tb-odr-item"
						v-for="record in records"
						:key="record.id"
					>
						<td
							class="ng-tb-col ng-tb-col-check align-middle d-none d-sm-flex d-print-none"
						>
							<div
								class="custom-control custom-control-sm custom-checkbox notext"
							>
								<input
									type="checkbox"
									class="custom-control-input"
									:id="`record${record.id}`"
									:value="record.id"
									v-model="selected"
								/>
								<label
									class="custom-control-label"
									:for="`record${record.id}`"
								></label>
							</div>
						</td>
						<template v-for="(h, c) in headers" :key="c">
							<td
								v-if="
									c == 'user_id' ||
										(record.email && c == 'name')
								"
								class="text-left"
							>
								<div class="user-card">
									<div class="user-avatar bg-primary">
										<img
											:src="
												record['avatar'] ||
													record[c]['avatar']
											"
											:alt="formatLabel(record[c])"
										/>
									</div>
									<div class="user-info">
										<span class="lead-text">
											{{ formatLabel(record[c], c) }}
										</span>
										<span class="sub-text">
											{{
												record["email"] ||
													record[c]["email"]
											}}
										</span>
									</div>
								</div>
							</td>
							<td
								v-else
								class="align-middle text-truncate"
								:data-title="h"
								v-html="formatLabel(record[c], c)"
							></td>
						</template>

						<td
							class="tb-tnx-action justify-content-end d-print-none"
							data-title=""
						>
							<div class="dropdown">
								<a
									class="text-soft dropdown-toggle btn btn-icon btn-trigger float-right"
									data-toggle="dropdown"
									aria-expanded="false"
								>
									<em class="icon ni ni-more-h"></em>
								</a>
								<div
									class="dropdown-menu dropdown-menu-left dropdown-menu-xs"
									style=""
								>
									<ul class="link-list-plain">
										<li>
											<a
												data-toggle="modal"
												:id="`modal-open-${record.id}`"
												:href="`#modal-${record.id}`"
											>
												<em
													class="icon ni ni-eye mr-2 text-info"
												></em>
												<span>
													View
												</span>
											</a>
										</li>
										<li>
											<a
												data-toggle="modal"
												:id="`modal-edit-${record.id}`"
												:href="
													`#modal-update-${record.id}`
												"
											>
												<em
													class="icon ni ni-pen mr-2 text-dark"
												></em>
												Edit
											</a>
										</li>
										<li>
											<a
												href="#"
												:id="
													`modal-delete-${record.id}`
												"
												@click.prevent="
													deleteItem(record.id)
												"
											>
												<em
													class="icon ni ni-trash-empty mr-2 text-danger"
												></em>
												Remove
											</a>
										</li>
									</ul>
								</div>
							</div>
						</td>

						<div
							class="modal fade"
							:id="'modal-' + record.id"
							tabindex="-1"
							role="dialog"
							:aria-labelledby="'modal-title-' + record.id"
							aria-hidden="true"
						>
							<div
								class="modal-dialog"
								:class="record.avatar ? `modal-sm` : `modal-lg`"
								role="document"
							>
								<div class="modal-content">
									<div class="modal-header bg-primary">
										<h5
											class="modal-title text-white"
											:id="'modal-title-' + record.id"
										>
											<slot
												name="title"
												:record="record"
											></slot>
										</h5>
										<button
											class="close"
											type="button"
											data-dismiss="modal"
											aria-label="Close"
											id="hideView"
										>
											<em
												class="icon ni ni-eye-off text-white"
											></em>
										</button>
									</div>
									<div class="modal-body text-left">
										<slot
											name="details"
											:record="record"
										></slot>
									</div>
								</div>
							</div>
						</div>

						<div
							class="modal fade"
							:id="'modal-update-' + record.id"
							tabindex="-1"
							role="dialog"
							:aria-labelledby="'modal-title-' + record.id"
							aria-hidden="true"
						>
							<div class="modal-dialog" role="document">
								<div class="modal-content">
									<div class="modal-header bg-primary">
										<h5
											class="modal-title text-white"
											:id="'modal-title-' + record.id"
										>
											Edit
											<slot
												name="title"
												:record="record"
											></slot>
										</h5>
										<button
											id="hideEditForm"
											class="close"
											type="button"
											data-dismiss="modal"
											aria-label="Close"
										>
											<em
												class="icon ni ni-eye-off text-white"
											></em>
										</button>
									</div>
									<div class="modal-body text-left">
										<slot
											name="form"
											:record="record"
										></slot>
									</div>
								</div>
							</div>
						</div>
					</tr>
				</template>

				<template v-else>
					<tr class="tb-odr-item alert alert-danger">
						<td :colspan="headerCount" class="text-center">
							<h4>
								<em
									class="icon ni ni-alert-c text-danger mr-2"
								></em>
								No record to display...
							</h4>
						</td>
					</tr>
				</template>
			</tbody>
		</table>
	</div>
</template>

<script lang="ts">
	import { defineComponent, computed, ref, watch } from "vue";
	import { format } from "date-fns";

	export default defineComponent({
		props: {
			headers: Object,
			records: Array,
		},

		emits: ["selected", "delete"],

		setup(props, { emit }) {
			const selected = ref([0]);

			function selectAll(e: any) {
				const iS = e.target.checked;

				if (!iS) {
					selected.value = [];
					return false;
				}

				props.records?.forEach((r: any) => {
					if (typeof r === "object" && r !== null) {
						const id = r["id"] || 0;
						selected.value.push(id);
					}
				});
			}

			function deleteItem(i: any) {
				emit("delete", i);
			}

			const headerCount = computed(() => {
				const h = props.headers;

				if (h !== undefined) {
					return Object.keys(h).length + 1;
				}

				return 1;
			});

			const dark = computed(() => false);
			//window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)

			function formatLabel(item: any, key = "id") {
				if (typeof item === "object" && item !== null) {
					if (item.name) {
						return item.name;
					} else if (item.title) {
						return item.title;
					}
				} else {
					if (key == "created_at" || key == "updated_at") {
						return format(new Date(item), "PPPP");
					} else {
						return item;
					}
				}
			}

			watch(
				() => selected.value,
				(v) => {
					emit("selected", v);
				}
			);

			return {
				deleteItem,
				headerCount,
				formatLabel,
				selected,
				selectAll,
				dark,
			};
		},
	});
</script>
