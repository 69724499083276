<template>
	<div
		class="ng-aside d-none d-print-none"
		data-content="sideNav"
		data-toggle-overlay="true"
		data-toggle-screen="lg"
		data-toggle-body="true"
		id="sideNav"
	>
		<div class="ng-sidebar-menu" data-simplebar>
			<ul class="ng-menu">
				<template v-for="s in routes" :key="s.name">
					<li class="ng-menu-heading" v-if="s.meta.sidebar">
						<h6 class="overline-title" :title="s.name">
							{{ s.name || s.meta.title }}
						</h6>
					</li>

					<template v-if="s.children">
						<template v-for="r in s.children">
							<li
								:key="r.name"
								v-if="r.meta.sidebar"
								class="ng-menu-item"
								:class="{
									'has-sub': r.children,
								}"
							>
								<template v-if="r.children">
									<a
										href="#"
										class="ng-menu-link ng-menu-toggle"
									>
										<span class="ng-menu-icon">
											<em
												class="icon ni"
												:class="`ni-${r.meta.icon}`"
											></em>
										</span>
										<span class="ng-menu-text">
											{{ r.name }}
										</span>
									</a>

									<ul class="ng-menu-sub" v-if="r.children">
										<template v-for="c in r.children">
											<li
												class="ng-menu-item"
												:key="c.path"
												v-if="c.meta.sidebar"
											>
												<router-link
													:to="
														c.path.length
															? r.path +
															  `/` +
															  c.path
															: r.path
													"
													class="ng-menu-link"
													:data-title="
														c.meta.title || c.name
													"
												>
													<span class="ng-menu-text">
														{{ c.name }}
													</span>
												</router-link>
											</li>
										</template>
									</ul>
									<!-- .ng-menu-sub -->
								</template>

								<template v-else>
									<router-link
										:to="r.path"
										class="ng-menu-link"
										:data-title="r.meta.title || r.name"
									>
										<span class="ng-menu-icon">
											<em
												class="icon ni"
												:class="`ni-${r.meta.icon}`"
											></em>
										</span>
										<span class="ng-menu-text">
											{{ r.name }}
										</span>
									</router-link>
								</template>
							</li>
						</template>
					</template>
				</template>
			</ul>

			<ul class="ng-menu ng-menu-sm">
				<li class="ng-menu-heading d-none">
					<span>
						Help Center
					</span>
				</li>
				<li class="ng-menu-item">
					<router-link to="/contact" class="ng-menu-link">
						<span class="ng-menu-text">
							Contact Support
						</span>
					</router-link>
				</li>
				<li class="ng-menu-item" v-if="user?.role == 'admin'">
					<router-link to="/users" class="ng-menu-link">
						<span class="ng-menu-text">
							User Accounts
						</span>
					</router-link>
				</li>
				<li class="ng-menu-item" v-if="user?.role == 'admin'">
					<router-link to="/settings" class="ng-menu-link">
						<span class="ng-menu-text">
							App Settings
						</span>
					</router-link>
				</li>
			</ul>
		</div>

		<div class="ng-aside-close">
			<a href="#" class="toggle" data-target="sideNav">
				<em class="icon ni ni-cross"></em>
			</a>
		</div>
	</div>
</template>

<script lang="ts">
	import { defineComponent } from "vue";
	export default defineComponent({
		props: {
			routes: Array,
			user: Object,
		}
	});
</script>
