<template>
	<div class="ng-block d-print-none">
		<div class="ng-block-content row">
			<div
				class="col-md-4 col-sm-12 justify-content-center align-middle d-none d-sm-inline-block"
			>
				<p class="my-4 text-center align-middle text-muted">
					Page <b>{{ data.current_page }}</b> of
					<b>{{ data.last_page }}</b> (<b>{{ data.from }}</b> -
					<b>{{ data.to }}</b> of <b>{{ data.total }}</b> results)
				</p>
			</div>

			<div class="col-md-8 col-sm-12 align-middle">
				<nav class="my-4">
					<ul
						class="pagination pagination-borderless pagination-primary justify-content-end mr-4"
					>
						<li
							class="page-item first"
							:class="{ disabled: isFirstPage }"
						>
							<a
								href="#"
								class="page-link"
								@click.prevent="onClickFirstPage"
								aria-label="Go to first page"
							>
								<em class="icon ni ni-chevrons-left"></em>
							</a>
						</li>

						<li
							class="page-item previous"
							:class="{ disabled: isFirstPage }"
						>
							<a
								href="#"
								class="page-link"
								@click.prevent="onClickPreviousPage"
								aria-label="Go to previous page"
							>
								<em class="icon ni ni-chevron-left"></em>
							</a>
						</li>

						<li
							v-for="page in pages"
							class="page-item"
							:class="{
								active: isCurrent(page.name),
								disabled: page.isDisabled,
							}"
							:key="page.name"
						>
							<a
								href="#"
								class="page-link"
								@click.prevent="onClickPage(page.name)"
								:aria-label="`Go to page number ${page.name}`"
							>
								{{ page.name }}
							</a>
						</li>

						<li
							class="page-item next"
							:class="{ disabled: isLastPage }"
						>
							<a
								href="#"
								class="page-link"
								@click.prevent="onClickNextPage"
								aria-label="Go to next page"
							>
								<em class="icon ni ni-chevron-right"></em>
							</a>
						</li>

						<li
							class="page-item last"
							:class="{ disabled: isLastPage }"
						>
							<a
								href="#"
								class="page-link"
								@click.prevent="onClickLastPage"
								aria-label="Go to last page"
							>
								<em class="icon ni ni-chevrons-right"></em>
							</a>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import { computed, defineComponent } from "vue";

	export default defineComponent({
		name: "Pagination",
		props: {
			max: {
				type: Number,
				required: false,
				default: 3,
			},
			range: {
				type: Number,
				required: false,
				default: 5,
			},
			data: {
				type: Object,
				required: true,
			},
		},
		emits: ["changed"],
		setup(props, { emit }) {
			const firstPage = computed(() => {
				if (props.data?.current_page === 1) {
					return 1;
				}

				if (props.data?.current_page === props.data?.last_page) {
					return props.data?.last_page - props.max + 1;
				}

				return props.data?.current_page - 1;
			});

			const lastPage = computed(() => {
				return Math.min(
					firstPage.value + props.max - 1,
					props.data?.last_page
				);
			});

			const pages = computed(() => {
				const range = [];

				for (let i = firstPage.value; i <= lastPage.value; i += 1) {
					range.push({
						name: i,
						//isDisabled: i === props.data?.current_page,
					});
				}

				return range;
			});

			const isFirstPage = computed(() => {
				return props.data?.current_page === 1;
			});

			const isLastPage = computed(() => {
				return props.data?.current_page === props.data?.last_page;
			});

			function onClickFirstPage() {
				emit("changed", 1);
			}

			function onClickPreviousPage() {
				emit("changed", props.data?.current_page - 1);
			}

			function onClickPage(page = 1) {
				emit("changed", page);
			}

			function onClickNextPage() {
				emit("changed", props.data?.current_page + 1);
			}

			function onClickLastPage() {
				emit("changed", props.data?.last_page);
			}

			function isCurrent(page = 1) {
				return props.data?.current_page === page;
			}

			return {
				firstPage,
				lastPage,
				pages,
				isFirstPage,
				isLastPage,
				onClickFirstPage,
				onClickPreviousPage,
				onClickPage,
				onClickNextPage,
				onClickLastPage,
				isCurrent,
			};
		},
	});
</script>
