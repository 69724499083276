import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "@/views/Home.vue";
import Base from "../../../../common/components/Base.vue";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/auth",
		name: "Authentication",
		component: () => import("@/views/LoginRegister.vue"),
		meta: {
			title: "Authentication",
			sidebar: false,
			icon: "growth",
		},
	},
	{
		path: "/",
		name: "Home",
		component: Base,
		meta: {
			sidebar: true,
		},
		children: [
			{
				path: "/",
				name: "Dashboard",
				component: Home,
				meta: {
					title: "Home",
					sidebar: true,
					icon: "home-alt",
				},
			},

			{
				path: "/users",
				name: "User Accounts",
				component: () => import("@/views/Users.vue"),
				meta: {
					title: "User Accounts",
					sidebar: false,
					icon: "growth",
				},
			},
		],
	},
	{
		path: "/pricing",
		name: "Calculations",
		component: Base,
		meta: {
			sidebar: true,
		},
		children: [
			{
				path: "/calculate",
				name: "Calculate",
				component: () => import("@/views/Calculate.vue"),
				meta: {
					title: "Calculate",
					sidebar: true,
					icon: "calc",
				},
			},

			{
				path: "/history",
				name: "History",
				component: Base,
				meta: {
					title: "Calculation History",
					sidebar: true,
					icon: "calendar",
				},
				children: [
					{
						path: "",
						name: "All History",
						component: () => import("@/views/Calculations.vue"),
						meta: {
							title: "Calculation History",
							sidebar: true,
						},
					},

					{
						path: "customer",
						name: "By Customer",
						component: () => import("@/views/CustomerHistory.vue"),
						meta: {
							title: "Sort By Customer",
							sidebar: true,
						},
					},
					
					{
						path: "product",
						name: "By Product",
						component: () => import("@/views/ProductHistory.vue"),
						meta: {
							title: "Sort By Product",
							sidebar: true,
						},
					},
				],
			},

			{
				path: "/calculations/:id",
				name: "View Calculation",
				component: () => import("@/views/Calculation.vue"),
				meta: {
					title: "View Calculation",
					sidebar: false,
					icon: "reload",
				},
			},
		],
	},

	{
		path: "/costs",
		name: "Costs",
		component: Base,
		meta: {
			sidebar: true,
		},
		children: [
			{
				path: "/costs/types",
				name: "Categories",
				component: () => import("@/views/CostTypes.vue"),
				meta: {
					title: "Cost Types",
					sidebar: true,
					icon: "sign-pound",
				},
			},

			{
				path: "/costs/view/fixed-costs",
				name: "Fixed Costs",
				component: () => import("@/views/Costs.vue"),
				meta: {
					title: "Fixed Costs",
					sidebar: true,
					icon: "label",
				},
			},

			{
				path: "/costs/view",
				name: "Packaging",
				component: Base,
				meta: {
					title: "Packaging Costs",
					sidebar: true,
					icon: "tag",
				},
				children: [
					{
						path: "primary-packaging",
						name: "Primary Packaging",
						component: () => import("@/views/Costs.vue"),
						meta: {
							title: "Primary Packaging",
							sidebar: true,
						},
					},

					{
						path: "box-types",
						name: "Box Types",
						component: () => import("@/views/Costs.vue"),
						meta: {
							title: "Box Types",
							sidebar: true,
						},
					},

					{
						path: "labelling",
						name: "Labelling",
						component: () => import("@/views/Costs.vue"),
						meta: {
							title: "Labelling",
							sidebar: true,
						},
					},

					{
						path: "pallets",
						name: "Pallets",
						component: () => import("@/views/Costs.vue"),
						meta: {
							title: "Pallet Costs",
							sidebar: true,
						},
					},
				],
			},
		],
	},
	{
		path: "/pallets",
		component: Base,
		meta: {
			sidebar: true,
			title: "Pallets",
		},
		children: [
			{
				path: "/pallets/types",
				name: "Shipping Formats",
				component: () => import("@/views/PalletTypes.vue"),
				meta: {
					title: "Shipping Formats",
					sidebar: true,
					icon: "package",
				},
			},
			{
				path: "/pallets/boxes",
				name: "Shipping Boxes",
				component: () => import("@/views/Pallets.vue"),
				meta: {
					title: "Boxes Per Pallet/AKE",
					sidebar: true,
					icon: "box",
				},
			},
		],
	},
	{
		path: "/hidden",
		name: "Hidden",
		component: Base,
		meta: {
			sidebar: false,
		},
		children: [
			{
				path: "/profile",
				name: "My Profile",
				component: () =>
					import("../../../../common/components/Profile.vue"),
				meta: {
					title: "My Profile",
					sidebar: false,
				},
			},
			{
				path: "/contact",
				name: "Contact Support",
				component: () =>
					import("../../../../common/components/Contact.vue"),
				meta: {
					title: "Contact Support",
					sidebar: false,
				},
			},
			{
				path: "/tickets",
				name: "Support Tickets",
				component: () =>
					import("../../../../common/components/Tickets.vue"),
				meta: {
					title: "Support Tickets",
					sidebar: false,
				},
			},
			{
				path: "/ticket/:id",
				name: "Support Ticket",
				component: () =>
					import("../../../../common/components/Ticket.vue"),
				meta: {
					title: "Support Ticket",
					sidebar: false,
				},
				props: true,
			},
			{
				path: "/settings",
				name: "App Settings",
				component: () => import("@/views/Settings.vue"),
				meta: {
					title: "App Settings",
					sidebar: false,
					icon: "setting",
				},
			},
		],
	},
	{
		name: "404",
		path: "/:pathMatch(.*)*",
		component: () => import("../../../../common/components/NotFound.vue"),
		meta: {
			title: "Page Not Found",
			sidebar: false,
		},
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	linkActiveClass: "current",
	linkExactActiveClass: "active",
	routes,
});

router.beforeEach(function(to, from, next) {
	setTimeout(() => {
		window.scrollTo(0, 0);
	}, 100);
	next();
});

router.beforeEach((to, from, next) => {
	const titled = to.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.title);
	if (titled) {
		document.title = `${titled.meta.title} - ${process.env.VUE_APP_NAME} | ${process.env.VUE_APP_COMPANY}`;
	}

	next();
});

export default router;
