
	import { computed, defineComponent } from "vue";

	export default defineComponent({
		name: "Pagination",
		props: {
			max: {
				type: Number,
				required: false,
				default: 3,
			},
			range: {
				type: Number,
				required: false,
				default: 5,
			},
			data: {
				type: Object,
				required: true,
			},
		},
		emits: ["changed"],
		setup(props, { emit }) {
			const firstPage = computed(() => {
				if (props.data?.current_page === 1) {
					return 1;
				}

				if (props.data?.current_page === props.data?.last_page) {
					return props.data?.last_page - props.max + 1;
				}

				return props.data?.current_page - 1;
			});

			const lastPage = computed(() => {
				return Math.min(
					firstPage.value + props.max - 1,
					props.data?.last_page
				);
			});

			const pages = computed(() => {
				const range = [];

				for (let i = firstPage.value; i <= lastPage.value; i += 1) {
					range.push({
						name: i,
						//isDisabled: i === props.data?.current_page,
					});
				}

				return range;
			});

			const isFirstPage = computed(() => {
				return props.data?.current_page === 1;
			});

			const isLastPage = computed(() => {
				return props.data?.current_page === props.data?.last_page;
			});

			function onClickFirstPage() {
				emit("changed", 1);
			}

			function onClickPreviousPage() {
				emit("changed", props.data?.current_page - 1);
			}

			function onClickPage(page = 1) {
				emit("changed", page);
			}

			function onClickNextPage() {
				emit("changed", props.data?.current_page + 1);
			}

			function onClickLastPage() {
				emit("changed", props.data?.last_page);
			}

			function isCurrent(page = 1) {
				return props.data?.current_page === page;
			}

			return {
				firstPage,
				lastPage,
				pages,
				isFirstPage,
				isLastPage,
				onClickFirstPage,
				onClickPreviousPage,
				onClickPage,
				onClickNextPage,
				onClickLastPage,
				isCurrent,
			};
		},
	});
