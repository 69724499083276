
    import {defineComponent, ref, watch } from 'vue'

    export default defineComponent({
        props: {
            actions: {
                type: Array,
                required: false
            },
            filters: {
                type: Array,
                required: false
            },
            queries: {
                type: Array,
                required: false
            }
        },

        emits: ["search"],

        setup(props, {emit}) {
            const keyword = ref("")

            watch(() => keyword.value, (v: any) => {
                emit("search", v)
            })

            return { keyword }
        }
    })
